import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import RootReducer from './store/reducers'
import { composeWithDevTools } from 'redux-devtools-extension' // 리덕스 개발자 도구
import 'react-app-polyfill/stable'
import 'bootstrap/dist/css/bootstrap.css'
import { GlobalStyle } from './components/default/styled'
import './services/dayjs'

const store = createStore(RootReducer, composeWithDevTools())

console.log(`[index.js] env : ${process.env.REACT_APP_ENV}`)

// 아래 공식문서 링크를 참조하여 react18로 마이그레이션하면서, new root API 를 적용하였습니다.
// https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
const container = document.getElementById('app')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
  <Provider store={store}>
    <GlobalStyle />
    <App />
  </Provider>,
)
