import { combineReducers } from 'redux'
import LoginReducer from './login'
import ModalReducer from './modal'
import BiddingReducer from './bidding'
import SolutionReducer from './solution'
import DashboardReducer from './dashboard'
import UTMReducer from './utm'
import ProjectMilestoneReducer from './projectMilestone'

const RootReducer = combineReducers({
  LoginReducer,
  ModalReducer,
  BiddingReducer,
  SolutionReducer,
  DashboardReducer,
  ProjectMilestoneReducer,
  UTMReducer,
})

export default RootReducer
export type RootState = ReturnType<typeof RootReducer>
