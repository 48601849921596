const JSONLocalData = localStorage.getItem('biddingData')
const localData = JSONLocalData === null ? null : JSON.parse(JSONLocalData)
const initialState = {
  proposalId: localData ? localData.proposalId : null,
  preparationStatus: localData ? localData.preparationStatus : null,
  requestCategoryIds: localData ? localData.requestCategoryIds : null,
  proposalPlatformTypeId: localData ? localData.proposalPlatformTypeId : null,
  isNeedToLaunch: localData ? localData.isNeedToLaunch : null,
  businessModelId: localData ? localData.businessModelId : null,
  businessModelCategoryIds: localData ? localData.businessModelCategoryIds : null,
  planningCategoryAndDetails: localData ? localData.planningCategoryAndDetails : [],
  etcFunctionIds: localData ? localData.etcFunctionIds : null,
  serviceKeywordIds: localData ? localData.serviceKeywordIds : null,
  conceptAndIntroduce: localData ? localData.conceptAndIntroduce : null,
  description: localData ? localData.description : null,

  // 타이틀 모음
  preparationStatusTitle: localData ? localData.preparationStatusTitle : null,
  requestCategoryTitles: localData ? localData.requestCategoryTitles : null,
  proposalPlatformTypeTitle: localData ? localData.proposalPlatformTypeTitle : null,
  isNeedToLaunchTitle: localData ? localData.isNeedToLaunchTitle : null,
  businessModelTitle: localData ? localData.businessModelTitle : null,
  businessModelCategoryTitles: localData ? localData.businessModelCategoryTitles : null,
  etcFunctionTitles: localData ? localData.etcFunctionTitles : null,
  serviceKeywordTitles: localData ? localData.serviceKeywordTitles : null,
  uploadTitle: localData ? localData.uploadTitle : null,
  descriptionTitle: localData ? localData.descriptionTitle : null,
}

const BiddingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INSERT': {
      return {
        ...state,

        proposalId: action.data.proposalId ? action.data.proposalId : state.proposalId,
        preparationStatus: action.data.preparationStatus ? action.data.preparationStatus : state.preparationStatus,
        requestCategoryIds: action.data.requestCategoryIds ? action.data.requestCategoryIds : state.requestCategoryIds,
        proposalPlatformTypeId: action.data.proposalPlatformTypeId
          ? action.data.proposalPlatformTypeId
          : state.proposalPlatformTypeId,
        isNeedToLaunch: action.data.isNeedToLaunch ? action.data.isNeedToLaunch : state.isNeedToLaunch,
        businessModelId: action.data.businessModelId ? action.data.businessModelId : state.businessModelId,
        businessModelCategoryIds: action.data.businessModelCategoryIds
          ? action.data.businessModelCategoryIds
          : state.businessModelCategoryIds,
        planningCategoryAndDetails: action.data.planningCategoryAndDetails
          ? action.data.planningCategoryAndDetails
          : state.planningCategoryAndDetails,
        etcFunctionIds: action.data.etcFunctionIds ? action.data.etcFunctionIds : state.etcFunctionIds,
        serviceKeywordIds: action.data.serviceKeywordIds ? action.data.serviceKeywordIds : state.serviceKeywordIds,
        conceptAndIntroduce: action.data.conceptAndIntroduce
          ? action.data.conceptAndIntroduce
          : state.conceptAndIntroduce,
        description: action.data.description ? action.data.description : state.description,

        // 타이틀 모음
        requestCategoryTitles: action.data.requestCategoryTitles
          ? action.data.requestCategoryTitles
          : state.requestCategoryTitles,
        preparationStatusTitle: action.data.preparationStatusTitle
          ? action.data.preparationStatusTitle
          : state.preparationStatusTitle,
        proposalPlatformTypeTitle: action.data.proposalPlatformTypeTitle
          ? action.data.proposalPlatformTypeTitle
          : state.proposalPlatformTypeTitle,
        isNeedToLaunchTitle: action.data.isNeedToLaunchTitle
          ? action.data.isNeedToLaunchTitle
          : state.isNeedToLaunchTitle,
        businessModelTitle: action.data.businessModelTitle ? action.data.businessModelTitle : state.businessModelTitle,
        businessModelCategoryTitles: action.data.businessModelCategoryTitles
          ? action.data.businessModelCategoryTitles
          : state.businessModelCategoryTitles,
        etcFunctionTitles: action.data.etcFunctionTitles ? action.data.etcFunctionTitles : state.etcFunctionTitles,
        serviceKeywordTitles: action.data.serviceKeywordTitles
          ? action.data.serviceKeywordTitles
          : state.serviceKeywordTitles,
        descriptionTitle: action.data.descriptionTitle ? action.data.descriptionTitle : state.descriptionTitle,
      }
    }
    case 'CLEAR': {
      return {
        proposalId: null,
        preparationStatus: null,
        requestCategoryIds: null,
        proposalPlatformTypeId: null,
        isNeedToLaunch: null,
        businessModelId: null,
        businessModelCategoryIds: null,
        planningCategoryAndDetails: null,
        etcFunctionIds: null,
        serviceKeywordIds: null,
        conceptAndIntroduce: null,
        description: null,
        // Title 모음
        preparationStatusTitle: null,
        requestCategoryTitles: null,
        proposalPlatformTypeTitle: null,
        isNeedToLaunchTitle: null,
        businessModelTitle: null,
        businessModelCategoryTitles: null,
        etcFunctionTitles: null,
        serviceKeywordTitles: null,
        uploadTitle: null,
        descriptionTitle: null,
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}

export default BiddingReducer
