const JSONLocalData = localStorage.getItem('biddingData')
const localData = JSONLocalData === null ? null : JSON.parse(JSONLocalData)
const initialState = {
  introduce: localData ? localData.introduce : '',
  projectPreparationStatus: localData ? localData.projectPreparationStatus : '',
  teamMember: localData ? localData.teamMember : '',
  selectedFeatures: localData ? localData.selectedFeatures : [],
  selectedFeaturesIdx: localData ? localData.selectedFeaturesIdx : [],
  coreFeature: localData ? localData.coreFeature : '',
  expectedPeriod: localData ? localData.expectedPeriod : '',
  budget: localData ? localData.budget : '',
}
const SolutionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INSERT': {
      return {
        ...state,
        introduce: action.data.introduce ? action.data.introduce : state.introduce,
        projectPreparationStatus: action.data.projectPreparationStatus
          ? action.data.projectPreparationStatus
          : state.projectPreparationStatus,
        teamMember: action.data.teamMember ? action.data.teamMember : state.teamMember,
        selectedFeatures: action.data.selectedFeatures ? action.data.selectedFeatures : state.selectedFeatures,
        selectedFeaturesIdx: action.data.selectedFeaturesIdx
          ? action.data.selectedFeaturesIdx
          : state.selectedFeaturesIdx,
        coreFeature: action.data.coreFeature ? action.data.coreFeature : state.coreFeature,
        expectedPeriod: action.data.expectedPeriod ? action.data.expectedPeriod : state.expectedPeriod,
        budget: action.data.budget ? action.data.budget : state.budget,
      }
    }
    case 'CLEAR': {
      return {
        introduce: '',
        projectPreparationStatus: '',
        teamMember: '',
        selectedFeatures: [],
        selectedFeaturesIdx: [],
        coreFeature: '',
        expectedPeriod: '',
        budget: '',
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default SolutionReducer
