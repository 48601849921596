import { DashboardStoreData } from '../../models/Dashboard'

const initialState: DashboardStoreData = {
  projectId: 0,
  title: '',
  projectStatus: '',
  teamType: '',
  subscription: 0,
  rating: {
    rating: '',
    comment: '',
  },
  team: {
    projectManager: [],
    planningDesign: [],
    development: [],
    expectedTimePerWeek: [],
  },
  cost: {
    totalCost: 0,
    subscriptionMonth: 0,
    thisMonthCost: 0,
  },
  milestone: {
    launching: 0,
    progress: 0,
    plan: 0,
    design: 0,
    development: 0,
  },
  file: {
    filename: '',
    url: '',
  },
}

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DATA': {
      return {
        ...state,
        ...action.data,
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default DashboardReducer
