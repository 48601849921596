const initialState = {
  RequesterLoginModal: false,
  RequesterChangePasswordModal: false,
  RequesterWithdrawalModal: false,
  ConsultingIntroModal: false,
  ConsultingTimeReservationModal: false,
  ConsultingImmediateReservationModal: false,
  RequesterPortfolioModal: false,
  LoadingModal: false,
  Is: false,
  ImmediateConsultingModal: false,
  LoginComplateModal: false,
  PasswordChangeComplateModal: false,
  consultingState: '노쇼',
  selectedPortfolioId: null,
  ReadyModal: false,
  SolutionImmediateConsultingModal: false,
  MenuModal: false,
  // 특정 프로젝트 리포트의 url. string | null
  reportUrl: null,
}

const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUESTER_LOGIN_ON': {
      return { ...state, RequesterLoginModal: true }
    }
    case 'REQUESTER_LOGIN_OFF': {
      return { ...state, RequesterLoginModal: false }
    }
    case 'REQUESTER_CHANGE_PASSWORD_ON': {
      return { ...state, RequesterChangePasswordModal: true }
    }
    case 'REQUESTER_CHANGE_PASSWORD_OFF': {
      return { ...state, RequesterChangePasswordModal: false }
    }
    case 'REQUESTER_WITHDRAWAL_ON': {
      return { ...state, RequesterWithdrawalModal: true }
    }
    case 'REQUESTER_WITHDRAWAL_OFF': {
      return { ...state, RequesterWithdrawalModal: false }
    }
    case 'CONSULTING_INTRO_MODAL_ON': {
      return { ...state, ConsultingIntroModal: true }
    }
    case 'CONSULTING_INTRO_MODAL_OFF': {
      return { ...state, ConsultingIntroModal: false }
    }
    case 'CONSULTING_TIME_RESERVATION_MODAL_ON': {
      return { ...state, ConsultingTimeReservationModal: true, consultingState: action.action }
    }
    case 'CONSULTING_TIME_RESERVATION_MODAL_OFF': {
      return { ...state, ConsultingTimeReservationModal: false }
    }
    case 'CONSULTING_IMMEDIATE_RESERVATION_MODAL_ON': {
      return { ...state, ConsultingImmediateReservationModal: true }
    }
    case 'CONSULTING_IMMEDIATE_RESERVATION_MODAL_OFF': {
      return { ...state, ConsultingImmediateReservationModal: false }
    }
    case 'REQUESTER_PORTFOLIO_MODAL_ON': {
      return { ...state, RequesterPortfolioModal: true, selectedPortfolioId: action.action }
    }
    case 'REQUESTER_PORTFOLIO_MODAL_OFF': {
      return { ...state, RequesterPortfolioModal: false }
    }
    case 'LOADING_MODAL_ON': {
      return { ...state, LoadingModal: true }
    }
    case 'LOADING_MODAL_OFF': {
      return { ...state, LoadingModal: false }
    }
    case 'IS_ON': {
      return { ...state, Is: true }
    }
    case 'IS_OFF': {
      return { ...state, Is: false }
    }
    case 'IMMEDIATE_CONSULTING_MODAL_ON': {
      return { ...state, ImmediateConsultingModal: true }
    }
    case 'IMMEDIATE_CONSULTING_MODAL_OFF': {
      return { ...state, ImmediateConsultingModal: false }
    }
    case 'LOGIN_COMPLATE_MODAL_ON': {
      return { ...state, LoginComplateModal: true }
    }
    case 'LOGIN_COMPLATE_MODAL_OFF': {
      return { ...state, LoginComplateModal: false }
    }
    case 'PASSWORD_CHANGE_COMPLATE_MODAL_ON': {
      return { ...state, PasswordChangeComplateModal: true }
    }
    case 'PASSWORD_CHANGE_COMPLATE_MODAL_OFF': {
      return { ...state, PasswordChangeComplateModal: false }
    }
    case 'READY_MODAL_ON': {
      return { ...state, ReadyModal: true }
    }
    case 'READY_MODAL_OFF': {
      return { ...state, ReadyModal: false }
    }
    case 'SOLUTION_IMMEDIATE_CONSULTING_MODAL_ON': {
      return { ...state, SolutionImmediateConsultingModal: true }
    }
    case 'SOLUTION_IMMEDIATE_CONSULTING_MODAL_OFF': {
      return { ...state, SolutionImmediateConsultingModal: false }
    }
    case 'REPORT_MODAL_ON': {
      return { ...state, reportUrl: action.payload }
    }
    case 'REPORT_MODAL_OFF': {
      return { ...state, reportUrl: null }
    }
    case 'MENU_MODAL_ON': {
      return { ...state, MenuModal: true }
    }
    case 'MENU_MODAL_OFF': {
      return { ...state, MenuModal: false }
    }
    default:
      return state
  }
}

export default ModalReducer
