// 23.02.14 TS 전환 작업 이전에 있던 함수

export function modifyLocalStorageItems(obj) {
  if (window.localStorage.hasOwnProperty(process.env.REACT_APP_GRIDGE_WEB_INFO || '')) {
    const keys = Object.keys(obj).map((val) => {
      return val
    })
    const values = Object.values(obj).map((val) => {
      return val
    })
    let data = JSON.parse(window.localStorage.getItem(process.env.REACT_APP_GRIDGE_WEB_INFO || '') || '')

    for (let i = 0; i < keys.length; i++) {
      data = { ...data, [keys[i]]: values[i] }
    }

    window.localStorage.setItem(process.env.REACT_APP_GRIDGE_WEB_INFO || '', JSON.stringify(data))
  } else {
    window.localStorage.setItem(process.env.REACT_APP_GRIDGE_WEB_INFO || '', JSON.stringify(obj))
  }
}

export function removeLocalStorageItems(...keys) {
  const data = JSON.parse(window.localStorage.getItem(process.env.REACT_APP_GRIDGE_WEB_INFO ?? '') ?? '{}')

  for (const el of keys) {
    if (data && data[el]) {
      delete data[el]
    }
  }

  window.localStorage.setItem(process.env.REACT_APP_GRIDGE_WEB_INFO || '', JSON.stringify(data))
}

export function getLocalStorageItem(key) {
  const gridgeWebInfo = JSON.parse(window.localStorage.getItem(process.env.REACT_APP_GRIDGE_WEB_INFO ?? '') ?? '{}')
  return gridgeWebInfo?.[key]
}

export function getSolutionLocalStorageItem(key) {
  const gridgeWebInfo = JSON.parse(window.localStorage.getItem('solutionData') ?? '{}')
  return gridgeWebInfo?.[key]
}

export function clearLoginData() {
  removeLocalStorageItems(
    'jwtRequester',
    'idRequester',
    'realNameRequester',
    'profileImageUrlRequester',
    'phoneNumberRequester',
    'proposalStatus',
    'proposalConsultingStatus',
    'proposalConsultingDate',
    'projectDepositStatus',
    'dateCount',
    'emailRequester',
    'companyName',
    'duty',
    'developmentTeamProcessStatus',
    'step',
  )
  removeSolutionData()

  removeBiddingData()
}

// 로그인 시 로컬스토리지 데이터 저장
export function saveLoginData(data) {
  modifyLocalStorageItems({
    jwtRequester: data.jwt,
    idRequester: data.id,
    emailRequester: data.email,
    realNameRequester: data.realName,
    profileImageUrlRequester: data.profileImageUrl,
    phoneNumberRequester: data.phoneNumber,
    proposalStatus: data.proposalStatus,
    proposalConsultingStatus: data.proposalConsultingStatus,
    proposalConsultingDate: data.proposalConsultingDate,
    projectDepositStatus: data.projectDepositStatus,
    dateCount: data.dateCount,
    companyName: data.companyName,
    duty: data.duty,
    developmentTeamProcessStatus: data.developmentTeamProcessStatus,
    step: data.step,
    projectId: data.projectId,
  })
}

// 23.02.14 TS 전환 작업하면서 추가된 함수

// export const saveLoginEmail = email => {
//   window.localStorage.setItem('email', email ?? '')
// }

// export const getLocalStorageEmail = () => {
//   return window.localStorage.getItem('email') ?? ''
// }

// export const getAdminId = () => {
//   const gridgeAdminInfo = JSON.parse(window.localStorage.getItem(process.env.REACT_APP_GRIDGE_WEB_INFO ?? '') ?? '')
//   return gridgeAdminInfo?.adminId ?? 'Error'
// }

// export const getGridgeAuthority = () => {
//   const gridgeAdminInfo = JSON.parse(window.localStorage.getItem(process.env.REACT_APP_GRIDGE_WEB_INFO ?? '') ?? '')
//   return gridgeAdminInfo?.gridgeAuthority ?? 'Error'
// }

// export const getJwt = () => {
//   const gridgeAdminInfo = JSON.parse(window.localStorage.getItem(process.env.REACT_APP_GRIDGE_WEB_INFO ?? '') ?? '')
//   return gridgeAdminInfo?.jwt ?? 'Error'
// }

export function saveBiddingData({
  // 디폴트는 number
  proposalId, // 기획서 아이디
  // step : requestReady
  preparationStatus, // 기획서 준비 유무 (얘는 string으로 ex."Y,N")
  // step : requestCategory
  requestCategoryIds, // 의뢰분야 아이디들 (얘는 string으로 ex."1,2")
  // step : platformType
  proposalPlatformTypeId, // 기획서 플랫폼 아이디
  isNeedToLaunch, // 출시예정 여부 ("Y", "N")
  // step : businessModel
  businessModelId, // 비즈니스 모델 아이디
  businessModelCategoryIds, // 비즈니스 모델 카테고리들 리스트 ex. [ 1, 2, 3 ]
  // step : planningCategoryAndDetail
  planningCategoryAndDetails, // 페이지명 상세 객체 리스트 ex. [{ "planningCategoryId": 1, "planningCategoryDetailId": 1 }, { "planningCategoryId": 2, "planningCategoryDetailId": 3 }]
  // step : etcFunction
  etcFunctionIds, // 기타 기능 리스트 ex. [ 1, 2, 3 ]
  // step : serviceKeyword
  serviceKeywordIds, // 키워드 리스트 ex. [ 1, 2, 3 ]
  conceptAndIntroduce, // 서비스 컨셉 String.
  description, // 서비스 설명
  // 서버가 아닌 BiddingHeader를 위해 따로 저장하는 타이틀 모음. 통일성을 위해 Title, Titles 로 통일
  preparationStatusTitle,
  requestCategoryTitles,
  proposalPlatformTypeTitle,
  isNeedToLaunchTitle,
  businessModelTitle,
  businessModelCategoryTitles,
  etcFunctionTitles,
  serviceKeywordTitles,
  uploadTitle, // 추가파일 업로드
  descriptionTitle,
}) {
  const JsonBiddingData = localStorage.getItem('biddingData')
  const biddingData = JsonBiddingData ? JSON.parse(JsonBiddingData) : null

  const newBiddingData = {
    proposalId: proposalId ? proposalId : biddingData ? biddingData.proposalId : null,
    preparationStatus: preparationStatus ? preparationStatus : biddingData ? biddingData.preparationStatus : null,
    requestCategoryIds: requestCategoryIds ? requestCategoryIds : biddingData ? biddingData.requestCategoryIds : null,
    proposalPlatformTypeId: proposalPlatformTypeId
      ? proposalPlatformTypeId
      : biddingData
      ? biddingData.proposalPlatformTypeId
      : null,
    isNeedToLaunch: isNeedToLaunch ? isNeedToLaunch : biddingData ? biddingData.isNeedToLaunch : null,
    businessModelId: businessModelId !== null ? businessModelId : biddingData ? biddingData.businessModelId : null,
    businessModelCategoryIds: businessModelCategoryIds
      ? businessModelCategoryIds
      : biddingData
      ? biddingData.businessModelCategoryIds
      : null,
    planningCategoryAndDetails: planningCategoryAndDetails
      ? planningCategoryAndDetails
      : biddingData
      ? biddingData.planningCategoryAndDetails
      : [],
    etcFunctionIds: etcFunctionIds ? etcFunctionIds : biddingData ? biddingData.etcFunctionIds : null,
    serviceKeywordIds: serviceKeywordIds ? serviceKeywordIds : biddingData ? biddingData.serviceKeywordIds : null,
    conceptAndIntroduce: conceptAndIntroduce
      ? conceptAndIntroduce
      : biddingData
      ? biddingData.conceptAndIntroduce
      : null,
    description: description,
    //
    // 타이틀 모음
    preparationStatusTitle: preparationStatusTitle
      ? preparationStatusTitle
      : biddingData
      ? biddingData.preparationStatusTitle
      : null,
    requestCategoryTitles: requestCategoryTitles
      ? requestCategoryTitles
      : biddingData
      ? biddingData.requestCategoryTitles
      : null,
    proposalPlatformTypeTitle: proposalPlatformTypeTitle
      ? proposalPlatformTypeTitle
      : biddingData
      ? biddingData.proposalPlatformTypeTitle
      : null,
    isNeedToLaunchTitle: isNeedToLaunchTitle
      ? isNeedToLaunchTitle
      : biddingData
      ? biddingData.isNeedToLaunchTitle
      : null,
    businessModelTitle: businessModelTitle ? businessModelTitle : biddingData ? biddingData.businessModelTitle : null,
    businessModelCategoryTitles: businessModelCategoryTitles
      ? businessModelCategoryTitles
      : biddingData
      ? biddingData.businessModelCategoryTitles
      : null,
    etcFunctionTitles: etcFunctionTitles ? etcFunctionTitles : biddingData ? biddingData.etcFunctionTitles : null,
    serviceKeywordTitles: serviceKeywordTitles
      ? serviceKeywordTitles
      : biddingData
      ? biddingData.serviceKeywordTitles
      : null,
    uploadTitle: uploadTitle ? serviceKeywordTitles : biddingData ? biddingData.uploadTitle : null,
    descriptionTitle: descriptionTitle,
  }
  const value = JSON.stringify(newBiddingData)
  localStorage.setItem('biddingData', value)
}

export const removeBiddingData = () => {
  window.localStorage.removeItem('biddingData')
}

// export const removeGridgeAdminInfo = () => {
//   window.localStorage.removeItem(process.env.REACT_APP_GRIDGE_WEB_INFO ?? '')
// }

export function saveSolutionData({
  introduce,
  projectPreparationStatus,
  teamMember,
  selectedFeatures,
  selectedFeaturesIdx,
  coreFeature,
  expectedPeriod,
  budget,
}) {
  const JsonSolutionData = localStorage.getItem('solutionData')
  const solutionData = JsonSolutionData ? JSON.parse(JsonSolutionData) : null

  const newSolutionData = {
    introduce: introduce ? introduce : solutionData ? solutionData.introduce : null,
    projectPreparationStatus: projectPreparationStatus
      ? projectPreparationStatus
      : solutionData
      ? solutionData.projectPreparationStatus
      : null,
    teamMember: teamMember ? teamMember : solutionData ? solutionData.teamMember : null,
    selectedFeatures: selectedFeatures ? selectedFeatures : solutionData ? solutionData.selectedFeatures : null,
    selectedFeaturesIdx: selectedFeaturesIdx
      ? selectedFeaturesIdx
      : solutionData
      ? solutionData.selectedFeaturesIdx
      : [],
    coreFeature: coreFeature ? coreFeature : solutionData ? solutionData.coreFeature : null,
    expectedPeriod: expectedPeriod ? expectedPeriod : solutionData ? solutionData.expectedPeriod : null,
    budget: budget ? budget : solutionData ? solutionData.budget : null,
  }
  const value = JSON.stringify(newSolutionData)
  localStorage.setItem('solutionData', value)
}

export const removeSolutionData = () => {
  window.localStorage.removeItem('solutionData')
}
