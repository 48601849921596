import { getLocalStorageItem } from '../../dataManager/localStorageManager'

const initialState = {
  user: {
    gridgeRequester: {
      jwt: getLocalStorageItem('jwtRequester'), // jwt
      id: getLocalStorageItem('idRequester'), // id
      email: getLocalStorageItem('emailRequester'), // 이메일
      realName: getLocalStorageItem('realNameRequester'), // 이름
      profileImageUrl: getLocalStorageItem('profileImageUrlRequester'), // 프로필 이미지
      phoneNumber: getLocalStorageItem('phoneNumberRequester'), // 휴대폰번호
      proposalStatus: getLocalStorageItem('proposalStatus'), // 기획서 상태 (null, )
      proposalConsultingStatus: getLocalStorageItem('proposalConsultingStatus'), // 컨설팅 상태
      proposalConsultingDate: getLocalStorageItem('proposalConsultingDate'), // 컨설팅 날짜 (null, "yyyy-mm-dd")
      dateCount: getLocalStorageItem('dateCount'), // 컨설팅 날짜 디데이 카운트 (null, Number)},
      projectDepositStatus: getLocalStorageItem('projectDepositStatus'), // 컨설팅 날짜 디데이 카운트 (null, Number)},
      companyName: getLocalStorageItem('companyName'),
      duty: getLocalStorageItem('duty'),
      developmentTeamProcessStatus: getLocalStorageItem('developmentTeamProcessStatus'),
      step: getLocalStorageItem('step'),
    },
  },
}

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN': {
      return {
        ...state,
        user: {
          ...state.user,
          gridgeRequester: {
            jwt: action.data.jwt,
            id: action.data.id,
            email: action.data.email,
            realName: action.data.realName,
            profileImageUrl: action.data.profileImageUrl,
            phoneNumber: action.data.phoneNumber,
            proposalStatus: action.data.proposalStatus,
            proposalConsultingStatus: action.data.proposalConsultingStatus,
            proposalConsultingDate: action.data.proposalConsultingDate,
            dateCount: action.data.dateCount,
            projectDepositStatus: action.data.projectDepositStatus,
            companyName: action.data.companyName,
            duty: action.data.duty,
            developmentTeamProcessStatus: action.data.developmentTeamProcessStatus,
            step: action.data.step,
            projectId: action.data.projectId,
          },
        },
      }
    }
    case 'LOGOUT': {
      return {
        ...state,
        user: {
          ...state.user,
          gridgeRequester: {},
        },
      }
    }
    case 'MODIFY_REQUESTER_PROFILE_IMAGE': {
      return {
        ...state,
        user: {
          ...state.user,
          gridgeRequester: {
            ...state.user.gridgeRequester,
            profileImageUrl: action.data,
          },
        },
      }
    }
    case 'MODIFY_REQUESTER_PHONE': {
      return {
        ...state,
        user: {
          ...state.user,
          gridgeRequester: {
            ...state.user.gridgeRequester,
            phoneNumber: action.data,
          },
        },
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default LoginReducer
