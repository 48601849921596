import styled, { createGlobalStyle } from 'styled-components'
import GreenBackground from '../../assets/default/bg_green_circle.svg'
import icPopupCancel from '../../assets/default/ic_popup_cancle.svg'
import GmarketSansTTFBold from '../../assets/fonts/GmarketSansTTFBold.ttf'

// 반응형 미디어 사이즈 정의
export const deviceSize = {
  mobile: '768px',
  desktopSmall: '1080px',
  desktop: '1920px',
}

// 디바이스 정의
export const device = {
  small: `(max-width: ${deviceSize.mobile})`,
  medium: `(min-width: ${deviceSize.mobile}) and (max-width: ${deviceSize.desktopSmall})`,
  desktop: `(min-width: ${deviceSize.desktopSmall})`,
}

// 디바이스 정의 by Matthias
export const deviceWithoutParenthesis = {
  small: `max-width: ${deviceSize.mobile}`,
  medium: `max-width: ${deviceSize.desktopSmall}`,
  large: `max-width: ${deviceSize.desktop}`,
}

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'GmarketSansTTFBold';
    src: local('GmarketSansTTFBold'), url(${GmarketSansTTFBold}) format('ttf');
    font-weight: bold;
    font-style: normal;
  }

  :root{
    scroll-behavior: auto;
  }

  html {
    // 128px => 12.8rem , 4px => 0.4rem 가능 하도록 기준을 10px로 설정
    font-size: 10px;
    -webkit-text-size-adjust: none;

    @media all and (${device.medium}) {
      // 태블릿 접속 사용자가 생각보다 많은 데이터를 기반으로 임시로 데스크탑의 70% 수준으로 볼 수 있도록 설정
      font-size: 7px;
    }

  }

  body {
    user-select: none;
    background: white;
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans KR', -apple-system, sans-serif, Roboto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden; // 100vw의 경우 스크롤바까지 vw에 포함되어 추가

    &:has(#modal-root:not(:empty)) {
      overflow: hidden !important;
      touch-action: none;
    }

    @media all and (max-width: ${deviceSize.mobile}) {
    }
  }

  // MS EDGE에서 input에 자동으로 type=password 내부 영역 보여주는 눈 모양 아이콘 삭제
  // 참고링크 = [https://docs.microsoft.com/en-us/microsoft-edge/web-platform/password-reveal]
  input::-ms-reveal {
    display: none;
  }

  // Safari에서 input에 자동으로 type=password 에 뜨는 열쇠 아이콘 삭제
  // 참고링크 = [https://newbedev.com/how-to-hide-autofill-safari-icon-in-input-field]
  input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  // tab 이동 시 버튼 아웃라인 삭제
  button:focus {
    outline: none;
    outline-offset: none;
  }
`

// 모달에 닫기버튼(X)
export const CloseModalButton = styled.div`
  background-image: url(${icPopupCancel});
  background-size: 2.49rem 2.49rem;
  width: 2.49rem;
  height: 2.49rem;
  cursor: pointer;
  position: absolute;
  top: 2.4rem;
  right: 2.6rem;
  @media all and (${device.small}) {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    top: ${(props) => props.top};
    right: ${(props) => props.right};
    background-size: ${(props) => props.backgroundSize};
  }
`

// 모달에 초록 동그라미
export const GreenCircleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${GreenBackground});
  background-size: 7.89rem 7.89rem;
  width: 7.89rem;
  height: 7.89rem;
  font-size: 4.8rem;
`

// 왼쪽 사이드바로 인해 left값이 들어간 wrap입니다..!
export const ContentWrap = styled.div`
  /* @media screen and (max-width: 1920px) {
    left: 45.6rem; //  제플린 값
  }

  @media screen and (max-width: 1720px) {
    left: 38.4rem;
  } */

  /* @media screen and (min-width: 1920px) { 
    left: 440rem;
  } */
  margin: 0 auto;

  /* BiddingHeader 좌측 고정시킴 */
  /* left: 10rem; */

  //width: 77.8rem;
  /* left: 456rem; */ //  제플린 값
  /* left: 392rem; // 맥북 크기 */
  padding-bottom: 50rem;
  position: relative;

  @media all and (${device.small}) {
    padding-bottom: 0;
  }
`

export const ProjectWorkerContentWrap = styled.div`
  width: 108rem;
  margin: 0 auto;
  @media all and (${device.small}) {
    width: 100%;
    padding: 0 2rem;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
`
