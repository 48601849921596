import { createAction, createReducer } from '@reduxjs/toolkit'
import { Utm } from '../../types/utm'

const initialState: Utm = {
  utmSource: '',
  utmMedium: '',
  utmCampaign: '',
  utmContent: '',
  utmTerm: '',
}

export const setUtmDatas = createAction<Utm>('SET_UTM_PARAMS')
export const resetUtmDatas = createAction<void>('RESET_UTM_PARAMS')

const UTMReducer = createReducer(initialState, (builder) => {
  builder.addCase(setUtmDatas, (state, action) => {
    state.utmCampaign = action.payload.utmCampaign
    state.utmContent = action.payload.utmContent
    state.utmMedium = action.payload.utmMedium
    state.utmSource = action.payload.utmSource
    state.utmTerm = action.payload.utmTerm
  })

  builder.addCase(resetUtmDatas, (state) => {
    state.utmCampaign = ''
    state.utmContent = ''
    state.utmMedium = ''
    state.utmSource = ''
    state.utmTerm = ''
  })
})

export default UTMReducer
